import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import AccountContext from "../../contexts/AccountContext";
import { getErrorMessage } from "../environment/validation";
import RHDynamicLoadingMask from "../../components/common/RHDynamicLoadingMask";
import EditDialog from "../environment/EditDialog";
import { SettingsIcon, ConnectedLink, DisconnectedLink, ArrowUpIcon, ArrowDownIcon } from "../../assets/svgs";
import { generateColor } from "../../common/colorUtils";
import { useReactTable, getCoreRowModel, getSortedRowModel, flexRender } from "@tanstack/react-table";

// Utility functions
const getStateColor = (state) => {
  const stateColors = {
    on: "bg-green-500",
    off: "bg-red-500",
    destroyed: "bg-red-500",
    creating: "bg-yellow-500",
    initializing: "bg-yellow-500",
    terminating: "bg-yellow-500",
    destroying: "bg-yellow-500",
    created: "bg-green-500",
  };
  return stateColors[state] || "bg-gray-500";
};

const getStatusSortValue = (state) => {
  if (state === "on") return 0;
  if (state === "off") return 2;
  return 1;
};

// Reusable components
const TableButton = ({ onClick, children, className, disabled = false, backgroundGradient, label, to }) => {
  const ButtonContent = (
    <button
      onClick={onClick}
      className={`text-white text-xs p-1 pl-4 rounded-md w-full transition-all duration-300 relative overflow-hidden ${className} ${
        disabled ? "opacity-50 cursor-not-allowed" : "hover:brightness-110"
      }`}
      disabled={disabled}
    >
      <div
        className="absolute inset-0 z-0"
        style={{
          background: backgroundGradient,
          opacity: 1,
        }}
      ></div>
      <div className="relative z-20">{children}</div>
    </button>
  );

  return to ? (
    <Link to={to} className="no-underline">
      {ButtonContent}
    </Link>
  ) : (
    ButtonContent
  );
};

const StatusIndicator = ({ state }) => (
  <div className="flex items-center justify-center" data-sort-value={getStatusSortValue(state)}>
    <div className="relative group">
      <div className={`w-3 h-3 rounded-full ${getStateColor(state)}`}></div>
      <div className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap z-10">
        {state || "Unknown"}
      </div>
    </div>
  </div>
);

const Switch = ({ checked, onChange, label, size = "normal" }) => {
  const sizeClasses = {
    small: {
      switch: "w-8 h-4",
      dot: "w-3 h-3",
      text: "text-xs",
    },
    normal: {
      switch: "w-10 h-6",
      dot: "w-4 h-4",
      text: "text-sm",
    },
  };

  const { switch: switchClass, dot: dotClass, text: textClass } = sizeClasses[size];

  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input type="checkbox" className="sr-only" checked={checked} onChange={onChange} />
        <div className={`block ${switchClass} rounded-full ${checked ? "bg-violet-600" : "bg-zinc-600"}`}></div>
        <div
          className={`dot absolute left-0.5 top-0.5 bg-white ${dotClass} rounded-full transition ${
            checked ? "transform translate-x-full" : ""
          }`}
        ></div>
      </div>
      <div className={`ml-2 text-zinc-300 ${textClass}`}>{label}</div>
    </label>
  );
};

const CopyableId = ({ id }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(id);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="relative group">
      <button onClick={handleCopy} className="text-zinc-400 hover:text-violet-300 transition-colors duration-200">
        {id}
      </button>
      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-zinc-700 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        {copied ? "Copied!" : "Click to copy"}
      </span>
    </div>
  );
};

const EnvironmentsTable = ({ data, currentStateData, isPermitted }) => {
  const [sorting, setSorting] = useState([{ id: "state", desc: false }]);

  const columns = useMemo(
    () => [
      {
        header: "Environment Name",
        accessorKey: "environmentName",
        cell: ({ row }) => (
          <TableButton
            to={`/org/${currentStateData?.org?.shortName}/product/${currentStateData?.product?.shortName}/environment/${row.original.shortName}`}
            backgroundGradient={generateColor(row.original.environmentId)}
            label="ENVIRONMENT"
          >
            {row.original.environmentName}
          </TableButton>
        ),
        enableSorting: true,
      },
      {
        header: "Environment ID",
        accessorKey: "environmentId",
        cell: ({ row }) => <CopyableId id={row.original.environmentId} />,
        enableSorting: true,
      },
      {
        header: "Connected Sandbox",
        accessorKey: "sandbox.sandboxName",
        cell: ({ row }) =>
          row.original.sandbox ? (
            <TableButton
              to={`/org/${currentStateData?.org?.shortName}/product/${currentStateData?.product?.shortName}/sandbox/${row.original.sandbox.shortName}`}
              backgroundGradient={generateColor(row.original.sandbox.sandboxId)}
              label="SANDBOX"
            >
              {row.original.sandbox.sandboxName}
            </TableButton>
          ) : (
            <div className="flex items-center justify-center h-full text-zinc-400">No Sandbox</div>
          ),
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "state",
        cell: ({ row }) => <StatusIndicator state={row.original.state} />,
        className: "text-center",
        enableSorting: true,
        sortingFn: (rowA, rowB, columnId) => {
          const valueA = getStatusSortValue(rowA.original.state);
          const valueB = getStatusSortValue(rowB.original.state);
          return valueA - valueB;
        },
      },
      {
        header: "Settings",
        cell: ({ row }) => {
          return isPermitted() ? (
            <Link
              to={`/org/${currentStateData?.org?.shortName}/product/${currentStateData?.product?.shortName}/environment/${row.original.shortName}/control-panel`}
              className="text-zinc-400 hover:text-violet-300"
            >
              <SettingsIcon width="16" height="16" />
            </Link>
          ) : (
            <span className="text-zinc-600">
              <DisconnectedLink width="16" height="16" />
            </span>
          );
        },
        className: "text-center",
      },
    ],
    [currentStateData, isPermitted]
  );

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const runningEnvironments = useMemo(() => {
    return data.filter((env) => env.state === "on").length;
  }, [data]);

  return (
    <div>
      <div className="text-xs mb-2">
        <span className="text-zinc-400">Running Environments:</span>{" "}
        <span className="text-green-500 font-semibold">{runningEnvironments}</span>
        <span className="text-zinc-400"> out of </span>
        <span className="text-zinc-300 font-semibold">{data.length}</span>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-xs min-w-[600px]">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`p-2 bg-zinc-700 ${header.column.columnDef.className || "text-left"} cursor-pointer`}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center justify-between">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <span className="ml-2">
                        {{
                          asc: <ArrowUpIcon width="12px" />,
                          desc: <ArrowDownIcon width="12px" />,
                        }[header.column.getIsSorted()] ?? null}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="border-b border-zinc-700">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={`p-2 ${cell.column.columnDef.className || ""}`}>
                    <div
                      className={
                        cell.column.columnDef.className === "text-center" ? "flex justify-center items-center" : ""
                      }
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SandboxesTable = ({ data, onEditClick, currentStateData }) => {
  const [sorting, setSorting] = useState([]);

  const columns = useMemo(
    () => [
      {
        header: "Sandbox Name",
        accessorKey: "sandboxName",
        cell: ({ row }) => (
          <TableButton
            to={`/org/${currentStateData?.org?.shortName}/product/${currentStateData?.product?.shortName}/sandbox/${row.original.shortName}`}
            backgroundGradient={generateColor(row.original.sandboxId)}
            label="SANDBOX"
          >
            {row.original.sandboxName}
          </TableButton>
        ),
        enableSorting: true,
      },
      {
        header: "Sandbox ID",
        accessorKey: "sandboxId",
        cell: ({ row }) => <CopyableId id={row.original.sandboxId} />,
        enableSorting: true,
      },
      {
        header: "Settings",
        cell: ({ row }) => (
          <button onClick={() => onEditClick(row.original, "sandbox")} className="text-zinc-400 hover:text-violet-300">
            <SettingsIcon width="16" height="16" />
          </button>
        ),
        className: "text-center",
      },
    ],
    [currentStateData, onEditClick]
  );

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="overflow-x-auto">
      <table className="w-full text-xs min-w-[400px]">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`p-2 bg-zinc-700 ${header.column.columnDef.className || "text-left"} cursor-pointer`}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div className="flex items-center justify-between">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <span className="ml-2">
                      {{
                        asc: <ArrowUpIcon width="12px" />,
                        desc: <ArrowDownIcon width="12px" />,
                      }[header.column.getIsSorted()] ?? null}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="border-b border-zinc-700">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={`p-2 ${cell.column.columnDef.className || ""}`}>
                  <div
                    className={
                      cell.column.columnDef.className === "text-center" ? "flex justify-center items-center" : ""
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default function RHProductPage() {
  const { getAccessTokenSilently } = useAuth();
  const { currentStateData, permissionData, sandboxCopyIsEnabled, refreshAccount, hasPermission } =
    useContext(AccountContext);

  const [items, setItems] = useState({ environments: [], sandboxes: [] });
  const [showArchivedEnvironments, setShowArchivedEnvironments] = useState(false);
  const [showArchivedSandboxes, setShowArchivedSandboxes] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState({ open: false, type: null, item: null });
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const REFRESH_INTERVAL = 30000; // 30 seconds

  const isPermitted = useCallback(() => {
    return hasPermission(
      "environment:config:edit",
      "product",
      currentStateData?.product?.productId,
      currentStateData,
      permissionData
    );
  }, [currentStateData, permissionData]);

  const fetchData = useCallback(async () => {
    if (!currentStateData?.product) {
      // console.log("Waiting for currentStateData...");
      return;
    }
    setErrorMsg("");
    setIsLoading(true);
    try {
      const activeProduct = currentStateData.product;
      const environments = activeProduct.environments || [];
      const sandboxes = activeProduct.sandboxes || [];

      // console.log("Fetched environments:", environments);

      setItems({ environments, sandboxes });
    } catch (error) {
      console.error("Error in fetchData:", error);
      setErrorMsg(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }, [currentStateData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData();
    }, REFRESH_INTERVAL);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [fetchData, REFRESH_INTERVAL]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = (item, type) => {
    setEditDialogOpen({ open: true, type, item });
  };

  const handleDialogClose = () => {
    setEditDialogOpen({ open: false, type: null, item: null });
  };

  const handleOnSave = async () => {
    await refreshAccount();
    fetchData();
  };

  const showAddEnvironmentDialog = () => {
    setEditDialogOpen({ open: true, type: "environment" });
  };

  const showAddSandboxDialog = () => {
    setEditDialogOpen({ open: true, type: "sandbox" });
  };

  const environments = useMemo(
    () =>
      items.environments
        .filter((env) => showArchivedEnvironments || !env.archive)
        .map((env) => ({
          ...env,
          sandbox: items.sandboxes.find((s) => s.sandboxId === env.connectedSandboxId),
        })),
    [items, showArchivedEnvironments]
  );

  const sandboxes = useMemo(
    () => items.sandboxes.filter((sandbox) => showArchivedSandboxes || !sandbox.archive),
    [items, showArchivedSandboxes]
  );

  if (isLoading) return <RHDynamicLoadingMask />;
  if (errorMsg) return <div className="text-red-500">{errorMsg}</div>;

  return (
    <div className="bg-zinc-900 min-h-screen p-2 md:p-6 text-zinc-100 text-sm">
      <div className="max-w-7xl mx-auto">
        <div className="bg-zinc-800 rounded-lg p-2 md:p-6 mb-8 shadow-lg">
          <div className="flex justify-between items-start mb-4">
            <h2 className="text-lg font-semibold">Environments</h2>
            <div className="flex flex-col items-end">
              <button
                onClick={showAddEnvironmentDialog}
                className="px-4 py-2 bg-violet-600 hover:bg-violet-700 text-white rounded-md transition-colors duration-300 text-xs mb-2"
              >
                Add Environment
              </button>
              <Switch
                checked={showArchivedEnvironments}
                onChange={() => setShowArchivedEnvironments(!showArchivedEnvironments)}
                label="Show Archived"
                size="small"
              />
            </div>
          </div>
          <EnvironmentsTable data={environments} currentStateData={currentStateData} isPermitted={isPermitted} />
        </div>

        <div className="bg-zinc-800 rounded-lg p-2 md:p-6 mb-8 shadow-lg">
          <div className="flex justify-between items-start mb-4">
            <h2 className="text-lg font-semibold">Sandboxes</h2>
            <div className="flex flex-col items-end">
              <button
                onClick={showAddSandboxDialog}
                className="px-4 py-2 bg-violet-600 hover:bg-violet-700 text-white rounded-md transition-colors duration-300 text-xs mb-2"
              >
                Add Sandbox
              </button>
              <Switch
                checked={showArchivedSandboxes}
                onChange={() => setShowArchivedSandboxes(!showArchivedSandboxes)}
                label="Show Archived"
                size="small"
              />
            </div>
          </div>
          <SandboxesTable data={sandboxes} onEditClick={handleEdit} currentStateData={currentStateData} />
        </div>
      </div>

      <EditDialog
        type={editDialogOpen.type}
        openRequest={{ open: editDialogOpen.open, [editDialogOpen.type]: editDialogOpen.item }}
        onClose={handleDialogClose}
        onSave={handleOnSave}
      />
    </div>
  );
}
