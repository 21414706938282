import { useState, useContext, useEffect, useCallback } from "react";
import { useAuth } from "contexts/AuthContext";
import { useQuery, useQueryClient, useMutation } from "react-query";

export const API_BASE =
  process.env.NODE_ENV === "production" ? window.env.REACT_APP_API_BASE_PATH : process.env.REACT_APP_API_BASE_PATH;

export const apiRequest = async (
  token,
  endpoint,
  replacements,
  body = null,
  extraHeaders = {},
  extraOptions = {},
  pageData = {}
) => {
  let url = API_BASE + endpoint?.path;

  // Extract placeholders from the URL
  const placeholderMatches = url.match(/\{[^\}]+\}/g) || [];

  for (const placeholder of placeholderMatches) {
    let key = placeholder.slice(1, -1);

    // Special ugh for xp_threshold_id
    if (key === "xp_threshold_id") {
      key = "xp_level_threshold_id";
    }
    // Special ugh x2 for sandbox_id
    if (key === "sandbox_identifier") {
      key = "sandbox_id";
    }
    // Special ugh x3 for inventory_bucket_rule_set_id
    if (key === "inventory_bucket_use_rule_set_id") {
      key = "rule_set_id";
    }

    let value = body?.[key] || replacements?.[key] || pageData.SelectedRecord?.[key] || pageData?.[key];
    if (value) {
      url = url.replace(placeholder, value);
    }
  }

  // Adding query parameters if present
  if (extraOptions.queryParams) {
    const queryParams = new URLSearchParams(extraOptions.queryParams).toString();
    url += `?${queryParams}`;
  }

  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    ...extraHeaders,
  });

  const options = {
    method: endpoint?.method.toUpperCase(),
    headers: headers,
    ...extraOptions.fetchOptions,
  };

  if (body && ["PUT", "PATCH", "DELETE"].includes(endpoint?.method.toUpperCase())) {
    options.body = JSON.stringify(body);
  }

  if (body && endpoint?.method.toUpperCase() === "POST") {
    let modifiedBody = body;

    //ugh
    if (pageData.PostDataArrayName === "data") {
      modifiedBody = { data: [body] };
    }

    options.body = JSON.stringify(modifiedBody);
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    let errorMsg = "An error occurred while fetching data";

    // Check if the response has content before attempting to parse as JSON
    if (response.headers.get("content-type")?.includes("application/json")) {
      try {
        const errorData = await response.json();
        errorMsg = errorData.message || JSON.stringify(errorData); // Customize based on your API's error structure
      } catch (errorParsing) {
        console.error("Error parsing error response:", errorParsing);
      }
    }

    throw {
      message: `apiRequest: Network response was not ok: ${response.status} ${response.statusText}`,
      errorMsg: errorMsg,
      response: response,
    };
  }

  // Handle 204 No Content specifically, as it should not attempt to parse JSON
  if (response.status === 204 || response.status === 422) {
    return null;
  }

  return response.json();
};

export const handleTableData = (
  token,
  pageData,
  operation,
  { data, replacements = {}, extraHeaders = {}, extraOptions = {} }
) => {
  let endpoint;
  switch (operation) {
    case "create":
      endpoint = pageData.Endpoints.find((e) => e.method === "post");
      break;
    case "update":
      endpoint =
        pageData.Endpoints.find((e) => e.method === "put") || pageData.Endpoints.find((e) => e.method === "patch");
      replacements = { ...replacements, itemId: data.id }; // Assuming 'itemId' is part of the 'data' for update
      break;
    case "delete":
      //$$SAINT This needs to be looked at on OPENAPI or Python generator
      endpoint = pageData.Endpoints.find((e) => e.method === "delete");

      if (!endpoint) {
        const getEndpoint = pageData.Endpoints.find((e) => e.method === "get" || e.method === "put");
        if (getEndpoint) {
          endpoint = { ...getEndpoint, method: "delete" };
        }
      }
      break;

    default:
      throw new Error(`Unsupported operation: ${operation}`);
  }
  return apiRequest(token, endpoint, replacements, data, extraHeaders, extraOptions, pageData);
};

// useCustomMutation.js
export const useCustomMutation = (
  queryClient,
  token,
  pageData,
  operation,
  handleTableData,
  {
    onSuccessCallback = () => {},
    onErrorCallback = () => {},
    onMutateCallback = () => {},
    onSettledCallback = () => {},
  } = {} // Provide a default empty object
) => {
  return useMutation(
    (data) => {
      return handleTableData(token, pageData, operation, data);
    },
    {
      onSuccess: (data, variables, context) => {
        if (onSuccessCallback) onSuccessCallback(data, variables, context);
      },
      onError: (error, variables, context) => {
        if (error?.response?.status === 204) {
          // Handle the 204 No Content success case. Not sure why this happens
          onSuccessCallback(response, variables, context);
        } else {
          if (onErrorCallback) onErrorCallback(error);
        }
      },
      onMutate: async (variables) => {
        if (onMutateCallback) await onMutateCallback(variables);
      },
      onSettled: (data, error, variables, context) => {
        if (onSettledCallback) onSettledCallback(data, error, variables, context);
      },
    }
  );
};

export const useApiToken = () => {
  const { getAccessTokenSilently } = useAuth();
  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  return token;
};

export async function fetchDirectly(args, params) {
  let paramsString;

  if (params) {
    if (params.name === "*") {
      paramsString = "";
    } else {
      const searchParams = new URLSearchParams(Object.entries(params));
      paramsString = "?" + searchParams.toString();
    }
  }

  let response = await fetch(
    `${API_BASE}/${
      args?.fullUrl ?? `v1/sandbox/${args?.sandboxId}/${args?.endpoint}${args?.dataId ? "/" + args?.dataId : ""}`
    }${paramsString ?? ""}`,
    {
      method: args?.method,
      headers: new Headers({
        Authorization: `Bearer ${args?.token}`,
        "Content-Type": "application/json",
      }),
      body: args.body ? JSON.stringify(args.body) : null,
    }
  );
  if (response?.ok) {
    return response.status === 204 ? [] : await response.json();
  } else {
    let newError = new Error("Something went wrong");
    let error_msg = await response.json();

    if (!Object.hasOwn(error_msg, "desc")) {
      newError.response = { data: { desc: JSON.stringify(error_msg) } };
    } else {
      newError.response = { data: error_msg };
    }

    throw newError;
  }
}

export const fetchTableData = async (token, idKey, get_endpoint, replacements, searchValue, value, options = []) => {
  const queryParams = new URLSearchParams();
  const normalizedValue = Array.isArray(value) ? value : [value].filter(Boolean);

  // Add idKeys to queryParams if no options are available
  if (!options.length && !normalizedValue.some((val) => options.some((option) => option?.id === val?.id))) {
    normalizedValue.forEach((item) => {
      const ids = Array.isArray(item?.id) ? item.id : [item?.id];
      ids.forEach((id) => id && queryParams.append(idKey + "s", id));
    });
  }

  if (searchValue) {
    queryParams.append("name", searchValue);
  }

  // Add fixed query parameters
  queryParams.append("expand", "*");
  queryParams.append("sort_by", idKey);
  queryParams.append("sort_order", "asc");
  queryParams.append("cursor", "0");
  queryParams.append("page_size", options.length ? 50 : normalizedValue[0]?.id?.length || 50);

  return apiRequest(token, get_endpoint, replacements, {}, {}, { queryParams: queryParams.toString() });
};
