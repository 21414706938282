import React, { useState, useEffect, useContext, useCallback } from "react";
import { useAuth } from "contexts/AuthContext";
import AccountContext from "contexts/AccountContext";
import { fetchDirectly } from "../../../../common/apiUtils";
import { validateData, getErrorMessage } from "../../../environment/validation";
import FormComponent from "../FormComponent";
import dayjs from "dayjs";

const GeneralConfig = ({ onNotification }) => {
  const { getAccessTokenSilently } = useAuth();
  const { currentStateData, refreshAccount } = useContext(AccountContext);

  const [environment, setEnvironment] = useState({});
  const [originalEnvironment, setOriginalEnvironment] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const title = "General Settings";
  const fields = [
    { headerName: "Name", field: "name", required: true },
    { headerName: "Short Name", field: "short_name", required: true },
    { headerName: "Primary Contact Email", field: "primary_contact_email", required: true },
    {
      headerName: "Sandbox",
      field: "sandbox_id",
      required: true,
      type: "select",
      options: currentStateData?.product?.sandboxes
        .filter((x) => !x.archive)
        .map((x) => ({ id: x.sandboxId, title: x.sandboxName })),
    },
    {
      headerName: "Authentication State",
      field: "authentication_state",
      required: true,
      type: "select",
      options: [
        { id: "open", title: "Open" },
        { id: "limited", title: "Limited" },
        { id: "locked", title: "Locked" },
      ],
    },
    { headerName: "Kick Before Hint", field: "kick_before_hint", required: false, type: "datetime" },
  ];

  const MAX_RETRIES = 3;

  const fetchData = useCallback(async () => {
    let attempts = 0;
    let lastError;

    while (attempts < MAX_RETRIES) {
      try {
        const endpoint = `v1/product/${currentStateData?.product?.productId}/environment/${currentStateData?.environment?.environmentId}`;
        const response = await fetchDirectly({
          fullUrl: endpoint,
          method: "GET",
          token: await getAccessTokenSilently(),
        });

        setEnvironment(response);
        setOriginalEnvironment(response);
        setIsLoading(false);
        setErrorMsg("");
        return; // Success, exit the function
      } catch (error) {
        lastError = error;
        attempts++;

        if (attempts < MAX_RETRIES) {
          onNotification(`Attempt ${attempts} failed. Retrying...`, "warning");
          await new Promise((resolve) => setTimeout(resolve, 1000 * attempts)); // Exponential backoff
        }
      }
    }

    // If we've reached this point, all retries have failed
    console.error(lastError);
    const errorMessage = getErrorMessage(lastError, fields);
    setErrorMsg(errorMessage);
    onNotification(`Failed to fetch data after ${MAX_RETRIES} attempts. Please try again later.`, "error");
    setIsLoading(false);
  }, [
    currentStateData?.product?.productId,
    currentStateData?.environment?.environmentId,
    getAccessTokenSilently,
    onNotification,
    fields,
  ]);

  // No need to pass fetchData as a dependency since it gets recreated when currentStateData changes.
  // This effect will run only when currentStateData.environment.environmentId changes. Both
  // are updated based on that.
  useEffect(() => {
    if (currentStateData?.environment?.environmentId) {
      fetchData();
    }
  }, [currentStateData?.environment?.environmentId]);

  const handleDataChange = (newData) => {
    setEnvironment(newData);
  };

  const handleSave = async () => {
    try {
      validateData(fields, environment);
      if (environment.kick_before_hint) {
        const parsedDate = dayjs(environment.kick_before_hint);
        if (!parsedDate.isValid()) {
          throw new Error("Invalid date format for Kick Before Hint");
        }
        if (parsedDate.isAfter(dayjs())) {
          throw new Error("Kick Before Hint cannot be set to a future date");
        }
      }

      const endpoint = `v1/product/${currentStateData?.product?.productId}/environment/${environment.environment_id}`;
      const response = await fetchDirectly({
        fullUrl: endpoint,
        method: "PUT",
        body: environment,
        token: await getAccessTokenSilently(),
      });

      await refreshAccount();
      setEnvironment(response);
      setOriginalEnvironment(response);
      setErrorMsg("");
      onNotification("Changes saved successfully", "success");
    } catch (error) {
      const errorMessage = getErrorMessage(error, fields);
      setErrorMsg(errorMessage);
      onNotification(errorMessage, "error");
    }
  };

  const handleCancel = () => {
    setEnvironment(originalEnvironment);
    setErrorMsg("");
    onNotification("Changes discarded", "info");
  };

  const hasChanges = JSON.stringify(environment) !== JSON.stringify(originalEnvironment);

  return (
    <div className="w-full">
      <FormComponent
        title={title}
        fields={fields}
        data={environment}
        onDataChange={handleDataChange}
        isLoading={isLoading}
        onSave={handleSave}
        onCancel={handleCancel}
        errorMsg={errorMsg}
        hasChanges={hasChanges}
      />
    </div>
  );
};

export default GeneralConfig;
