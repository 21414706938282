import React from "react";

const CustomBooleanInput = ({ id, name, value, onChange, required, className, label }) => {
  return (
    <div className="flex items-center py-2">
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={!!value}
        onChange={onChange}
        required={required}
        className={`w-4 h-4 text-violet-600 bg-gray-700 border-gray-600 rounded focus:ring-violet-500 ${className}`}
      />
      <label htmlFor={id} className="ml-2 text-sm font-medium text-gray-300">
        {label}
      </label>
    </div>
  );
};

export default CustomBooleanInput;
