import { Switch, Route, Redirect } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "contexts/AuthContext";
import Layout from "layouts/Layout";
import { AccountContextProvider } from "contexts/AccountContext";
import { RHMessageProvider } from "contexts/messagebar/RHMessageContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { DataProvider } from "contexts/DataContext";

import RHKVConfigPage from "views/sandbox/RHKeyValueConfigPage";
import RHOrgPage from "views/org/RHOrgPage";
import RHProfilePage from "views/RHProfilePage";
import RHProductPage from "views/product/RHProductPage";
import RHEnvironmentPage from "views/environment/RHEnvironmentPage";
import RHEnvironmentDetailPage from "views/environment/RHEnvironmentDetailPage";
import RHSandboxPage from "views/sandbox/RHSandboxPage";
import RHOrgAnalytics from "views/org/RHOrgAnalyticsPage";
import RHOrgClusters from "views/org/RHOrgClustersPage";
import RHOrgApiDocsPage from "views/org/RHOrgApiDocsPage";
import RHPortalAdmin from "views/portalAdmin/RHPortalAdmin";
import RHOrgStructure from "views/portalAdmin/RHOrgStructure";
import RHGameConfigPage from "views/sandbox/RHGameConfigPage";
import ControlPanel from "./views/ControlPanel/ControlPanel";

import RHPricePointPage from "views/sandbox/catalog/RHPricePointPage";

import RHPortalPermissions from "views/portalAdmin/RHPortalPermissions";

import ArmadaGeoLocations from "views/armada/ArmadaGeoLocations";
import ArmadaDCProviders from "views/armada/ArmadaDCProviders";
import ArmadaDCLocations from "views/armada/ArmadaDCLocations";
import ArmadaMachineImages from "views/armada/ArmadaMachineImages";
import ArmadaDCContracts from "views/armada/ArmadaDCContracts";
import ArmadaMachineClasses from "views/armada/ArmadaMachineClasses";
import ArmadaProductAssignments from "views/armada/ArmadaProductAssignments";
import ArmadaHosts from "views/armada/ArmadaHosts";
import ArmadaHome from "views/armada";
import RHOrgAccountAndPermissionManagement from "views/org/userManagement/RHOrgAccountAndPermissionManagement";
import RHProductPolicyPage from "views/product/RHProductPolicyPage";
import RHProductClientPage from "views/product/RHProductClientPage";
import ToolsPage from "views/Tools";
import RHDynamicPage from "./views/RHDynamicPage";
import RHDynamicLoadingMask from "./components/common/RHDynamicLoadingMask";

export default function RHMainApp() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth();

  // Create a client
  const queryClient = new QueryClient();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }

    const lock = document.createElement("meta");
    lock.name = "darkreader-lock";
    document.head.appendChild(lock);
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <RHDynamicLoadingMask loadMessage="" />;
  }

  if (!isAuthenticated) {
    // You might want to render null or some placeholder content here
    // while waiting for the redirect to happen
    return <RHDynamicLoadingMask loadMessage="" />;
  }

  return (
    <AccountContextProvider>
      <DataProvider>
        <QueryClientProvider client={queryClient}>
          <RHMessageProvider>
            <Layout>
              <Switch>
                <Route path="/tools">
                  <ToolsPage />
                </Route>
                <Route path="/admin/armada/dc-location">
                  <ArmadaDCLocations />
                </Route>
                <Route path="/admin/armada/dc-provider">
                  <ArmadaDCProviders />
                </Route>
                <Route path="/admin/armada/geo-location">
                  <ArmadaGeoLocations />
                </Route>
                <Route path="/admin/armada/machine-image">
                  <ArmadaMachineImages />
                </Route>
                <Route path="/admin/armada/dc-contract">
                  <ArmadaDCContracts />
                </Route>
                <Route path="/admin/armada/machine-class">
                  <ArmadaMachineClasses />
                </Route>
                <Route path="/admin/armada/product-assignment">
                  <ArmadaProductAssignments />
                </Route>
                <Route path="/admin/armada/host">
                  <ArmadaHosts />
                </Route>
                <Route path="/admin/armada">
                  <ArmadaHome />
                </Route>
                <Route path="/admin/dashboard">
                  <RHDynamicPage page="adminDashboard" />
                </Route>
                <Route path="/admin/orgstructure">
                  <RHOrgStructure />
                </Route>
                <Route path="/admin/permissions">
                  <RHPortalPermissions />
                </Route>
                <Route path="/admin">
                  <RHPortalAdmin />
                </Route>
                <Route path="/profile">
                  <RHProfilePage />
                </Route>
                <Route path="/org/:orgId/product/:productId/sandbox/:sandboxId/pricing-config">
                  <RHPricePointPage />
                </Route>
                <Route path="/org/:orgId/product/:productId/sandbox/:sandboxId/kv-editor">
                  <RHKVConfigPage />
                </Route>
                <Route
                  path="/org/:orgId/product/:productId/sandbox/:sandboxId/:apiId"
                  render={(props) => <RHDynamicPage page={props.match.params.apiId} />}
                />
                <Route path="/org/:orgId/product/:productId/sandbox/:sandboxId/game-config">
                  <RHGameConfigPage />
                </Route>
                <Route path="/org/:orgId/product/:productId/policies">
                  <RHProductPolicyPage />
                </Route>
                <Route path="/org/:orgId/product/:productId/clients">
                  <RHProductClientPage />
                </Route>
                <Route path="/org/:orgId/product/:productId/environment/:environmentId/control-panel">
                  <ControlPanel />
                </Route>
                <Route path="/org/:orgId/product/:productId/environment/:environmentId/files">
                  <RHDynamicPage />
                </Route>
                <Route path="/org/:orgId/product/:productId/environment/:environmentId/options">
                  <RHEnvironmentDetailPage />
                </Route>
                <Route path="/org/:orgId/product/:productId/environment/:environmentId">
                  <RHEnvironmentPage />
                </Route>
                <Route path="/org/:orgId/product/:productId/sandbox/:sandboxId">
                  <RHSandboxPage />
                </Route>
                <Route path="/org/:orgId/account-management">
                  <RHOrgAccountAndPermissionManagement />
                </Route>
                <Route path="/org/:orgId/analytics">
                  <RHOrgAnalytics />
                </Route>
                <Route path="/org/:orgId/clusters">
                  <RHOrgClusters />
                </Route>
                <Route path="/org/:orgId/apidocs">
                  <RHOrgApiDocsPage />
                </Route>
                <Route path="/org/:orgId/product/:productId">
                  <RHProductPage />
                </Route>
                <Route path="/org/:orgId">
                  <RHOrgPage />
                </Route>
                <Redirect to="/" />
              </Switch>
            </Layout>
          </RHMessageProvider>
        </QueryClientProvider>
      </DataProvider>
    </AccountContextProvider>
  );
}
