import React, { useState, useMemo, useContext, useEffect, useCallback } from "react";
import GeneralConfig from "./components/Environment/GeneralConfig";
import VivoxConfig from "./components/Environment/VivoxConfig";
import FileConfig from "./components/Environment/FileConfig";
import AccountContext from "contexts/AccountContext";
import { useAuth } from "contexts/AuthContext";
import EnvironmentSidebar from "./components/Environment/EnvironmentSidebar";
import Notification from "../../components/common/Notification";
import Modal from "./components/Modal";
import { fetchDirectly } from "../../common/apiUtils";

const menuItems = ["General", "File", "Vivox"];

const allFields = {
  General: ["Name", "Short Name", "Primary Contact Email", "Sandbox", "Authentication State", "Kick Before Hint"],
  File: [
    "File",
    "Match File TTL Days",
    "Match Developer File TTL Days",
    "Allow Files For Match",
    "Allow Developer Files For Match",
  ],
  Vivox: ["Vivox", "Voip", "Enabled", "Server", "Key Issuer", "Key Lifetime", "Signing Algorithm", "Signing Key"],
};

const ControlPanel = () => {
  const [activeTab, setActiveTab] = useState("General");
  const [searchQuery, setSearchQuery] = useState("");
  const [environment, setEnvironment] = useState(null);
  const [allSearchableItems, setAllSearchableItems] = useState([]);
  const [notificationProps, setNotificationProps] = useState({
    message: "",
    severity: "info",
  });
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: null,
    environmentName: "",
    environmentId: null,
    isArchiveAction: false,
  });

  const { currentStateData, permissionData, sandboxCopyIsEnabled, refreshAccount, hasPermission } =
    useContext(AccountContext);

  const { getAccessTokenSilently } = useAuth();

  const isPermitted = useCallback(() => {
    if (!currentStateData || !currentStateData.product) {
      return false; // If currentStateData or product is not available, user is not permitted
    }
    return hasPermission(
      "environment:config:edit",
      "product",
      currentStateData?.product?.productId ?? "", // Use optional chaining and provide a default value
      currentStateData,
      permissionData
    );
  }, [currentStateData, permissionData, hasPermission]);

  const handleNotification = (message, severity) => {
    setNotificationProps({ message, severity });
  };

  const fetchEnvironmentData = async () => {
    if (!currentStateData?.product?.productId || !currentStateData?.environment?.environmentId) {
      console.log("Missing product or environment ID, cannot fetch environment data");
      return;
    }

    try {
      const endpoint = `v1/product/${currentStateData.product.productId}/environment/${currentStateData.environment.environmentId}`;
      const response = await fetchDirectly({
        fullUrl: endpoint,
        method: "GET",
        token: await getAccessTokenSilently(),
      });
      setEnvironment(response);
    } catch (error) {
      console.error("Failed to fetch environment data:", error);
      handleNotification("Failed to fetch environment data", "error");
    }
  };

  useEffect(() => {
    fetchEnvironmentData();
    const items = Object.entries(allFields).flatMap(([tab, fields]) => fields.map((field) => ({ tab, field })));
    setAllSearchableItems(items);
  }, [currentStateData]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchResultClick = (result) => {
    setActiveTab(result.tab);
    setSearchQuery("");
  };

  const searchResults = useMemo(() => {
    if (!searchQuery.trim()) return [];
    const lowerQuery = searchQuery.toLowerCase();
    return allSearchableItems.filter((item) => item.field.toLowerCase().includes(lowerQuery));
  }, [searchQuery, allSearchableItems]);

  const clearNotification = () => {
    setNotificationProps({ message: "", severity: "info" });
  };

  const handleSpinActionConfirmation = (action, environmentName, environmentId) => {
    if (environment.archive && action === "up") {
      handleNotification("Cannot spin up an archived environment. Please unarchive it first.", "warning");
      return;
    }
    setModalState({
      isOpen: true,
      action,
      environmentName,
      environmentId,
      isArchiveAction: false,
    });
  };

  const handleArchiveConfirmation = (environmentName, environmentId, isArchive) => {
    setModalState({
      isOpen: true,
      action: isArchive ? "archive" : "unarchive",
      environmentName,
      environmentId,
      isArchiveAction: true,
    });
  };

  const pollEnvironmentStatus = async (environmentId, attempts = 3, interval = 2000) => {
    for (let i = 0; i < attempts; i++) {
      await new Promise((resolve) => setTimeout(resolve, interval));
      await fetchEnvironmentData();

      if (environment?.state !== "initiating" && environment?.state !== "spinning_down") {
        break;
      }
    }
  };

  const handleModalConfirm = async () => {
    const { action, environmentName, environmentId, isArchiveAction } = modalState;
    setModalState({
      isOpen: false,
      action: null,
      environmentName: "",
      environmentId: null,
      isArchiveAction: false,
    });

    if (isArchiveAction) {
      try {
        const endpoint = `v1/product/${currentStateData?.product?.productId}/environment/${environmentId}`;
        const updatedEnvironment = {
          ...environment,
          archive: action === "archive",
        };
        await fetchDirectly({
          fullUrl: endpoint,
          method: "PUT",
          body: updatedEnvironment,
          token: await getAccessTokenSilently(),
        });
        handleNotification(`Environment ${action === "archive" ? "archived" : "unarchived"} successfully`, "success");
        fetchEnvironmentData();
      } catch (error) {
        console.error(`Error during ${action} action:`, error);
        handleNotification(`Failed to ${action} environment ${environmentName}: ${error.message}`, "error");
      }
    } else {
      if (environment.archive && action === "up") {
        handleNotification("Cannot spin up an archived environment. Please unarchive it first.", "warning");
        return;
      }

      if (!environmentId) {
        console.error("Environment ID is missing");
        handleNotification(
          `Failed to ${action === "up" ? "spin up" : "spin down"} environment: Missing environment ID`,
          "error"
        );
        return;
      }

      try {
        const endpoint = `v1/product/${currentStateData?.product?.productId}/environment/${environmentId}/${
          action === "up" ? "spin-up" : "spin-down"
        }`;

        await fetchDirectly({
          fullUrl: endpoint,
          method: "POST",
          token: await getAccessTokenSilently(),
        });

        handleNotification(
          `Environment ${environmentName} ${action === "up" ? "spun up" : "spun down"} successfully`,
          "success"
        );

        pollEnvironmentStatus(environmentId);
      } catch (error) {
        console.error(`Error during ${action} action:`, error);
        handleNotification(
          `Failed to ${action === "up" ? "spin up" : "spin down"} environment ${environmentName}: ${error.message}`,
          "error"
        );
      }
    }
  };

  const handleModalCancel = () => {
    setModalState({
      isOpen: false,
      action: null,
      environmentName: "",
      environmentId: null,
      isArchiveAction: false,
    });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "General":
        return <GeneralConfig onNotification={handleNotification} />;
      case "File":
        return <FileConfig onNotification={handleNotification} />;
      case "Vivox":
        return <VivoxConfig onNotification={handleNotification} />;
      default:
        return <div>Tab content not found</div>;
    }
  };

  // Wrap the entire component render logic in a null check for currentStateData
  if (!currentStateData) {
    return (
      <div className="min-h-screen bg-zinc-900 text-zinc-300 flex flex-col">
        <header className="bg-zinc-800 p-4 flex justify-between items-center">
          <h1 className="text-lg font-bold">ENVIRONMENT SETTINGS</h1>
        </header>
        <div className="flex-grow flex items-center justify-center">
          <div className="text-center p-6 bg-zinc-800 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-2">Loading...</h2>
            <p className="text-zinc-400">Please wait while we load your data.</p>
          </div>
        </div>
      </div>
    );
  }

  if (!isPermitted()) {
    return (
      <div className="min-h-screen bg-zinc-900 text-zinc-300 flex flex-col">
        <header className="bg-zinc-800 p-4 flex justify-between items-center">
          <h1 className="text-lg font-bold">ENVIRONMENT SETTINGS</h1>
        </header>
        <div className="flex-grow flex items-center justify-center">
          <div className="text-center p-6 bg-zinc-800 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-2">Access Denied</h2>
            <p className="text-zinc-400">You do not have permission to view this page.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-zinc-900 text-zinc-300 flex flex-col">
      {/* Header */}
      <header className="bg-zinc-800 p-4 flex flex-col md:flex-row justify-between items-center">
        <h1 className="text-lg font-bold mb-4 md:mb-0">ENVIRONMENT SETTINGS</h1>
        {/* Search input */}
        <div className="relative w-full md:w-[250px]">
          <input
            type="text"
            placeholder="Search settings..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="bg-zinc-700 text-white text-sm px-3 py-1.5 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500 w-full"
          />
          {/* Search results */}
          {searchQuery && searchResults.length > 0 && (
            <div className="absolute mt-1 w-full bg-zinc-800 rounded-md shadow-lg max-h-60 overflow-auto z-10">
              {searchResults.map((result, index) => (
                <div
                  key={index}
                  className="px-3 py-1.5 hover:bg-zinc-700 cursor-pointer"
                  onClick={() => handleSearchResultClick(result)}
                >
                  <div className="font-medium text-sm">{result.field}</div>
                  <div className="text-xs text-zinc-400">{result.tab}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </header>

      <div className="flex-grow flex flex-col lg:flex-row">
        <main className="flex-grow overflow-hidden flex flex-col">
          {/* Tab navigation */}
          <div className="p-1 overflow-x-auto border-b border-zinc-700">
            <div className="flex space-x-1">
              {menuItems.map((item) => (
                <button
                  key={item}
                  onClick={() => setActiveTab(item)}
                  className={`flex-shrink-0 px-4 py-2 text-xs font-medium rounded-sm focus:outline-none ${
                    activeTab === item ? "bg-violet-500 text-white" : "text-zinc-300 hover:bg-zinc-700 hover:text-white"
                  }`}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          {/* Tab content */}
          <div className="flex-grow overflow-y-auto p-6">
            <div className="max-w-full">{renderTabContent()}</div>
          </div>
        </main>
        {/* Sidebar */}
        <aside className="w-full lg:w-[250px] lg:min-w-[250px] lg:max-w-[250px] flex justify-center lg:justify-start">
          <div className="w-full h-full">
            <EnvironmentSidebar
              environment={environment}
              onSpinActionConfirmation={handleSpinActionConfirmation}
              onArchiveConfirmation={handleArchiveConfirmation}
              onNotification={handleNotification}
              onRefresh={fetchEnvironmentData}
              currentStateData={currentStateData}
              getAccessTokenSilently={getAccessTokenSilently}
            />
          </div>
        </aside>
      </div>

      {/* Notification */}
      {notificationProps.message && (
        <Notification
          message={notificationProps.message}
          severity={notificationProps.severity}
          clearMessage={clearNotification}
        />
      )}

      {/* Modal */}
      <Modal
        isOpen={modalState.isOpen}
        onClose={handleModalCancel}
        onConfirm={handleModalConfirm}
        title="Confirm Action"
        message={
          modalState.isArchiveAction
            ? `Are you sure you want to ${modalState.action} the ${modalState.environmentName} environment?`
            : `Are you sure you want to spin ${modalState.action} the ${modalState.environmentName} environment?`
        }
      />
    </div>
  );
};

export default ControlPanel;
