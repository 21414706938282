import React, { useState, useEffect, useContext, useCallback } from "react";
import { useAuth } from "contexts/AuthContext";
import AccountContext from "contexts/AccountContext";
import { fetchDirectly } from "../../../../common/apiUtils";
import FormComponent from "../FormComponent";
import { validateData, getErrorMessage } from "../../../environment/validation";

const FileConfig = ({ onNotification }) => {
  const { getAccessTokenSilently } = useAuth();
  const { currentStateData } = useContext(AccountContext);

  const [fileConfig, setFileConfig] = useState({});
  const [originalFileConfig, setOriginalFileConfig] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const title = "File Settings";
  const fields = [
    { headerName: "Match File TTL Days", field: "match_file_ttl_days", type: "number", required: false },
    {
      headerName: "Match Developer File TTL Days",
      field: "match_developer_file_ttl_days",
      type: "number",
      required: false,
    },
    { headerName: "Allow Files For Match", field: "allow_files_for_match", type: "boolean", required: false },
    {
      headerName: "Allow Developer Files For Match",
      field: "allow_developer_files_for_match",
      type: "boolean",
      required: false,
    },
  ];

  const MAX_RETRIES = 3;

  const fetchFileConfig = useCallback(async () => {
    let attempts = 0;
    let lastError;

    while (attempts < MAX_RETRIES) {
      try {
        const endpoint = `v1/environment/${currentStateData?.environment?.environmentId}/file`;
        const response = await fetchDirectly({
          fullUrl: endpoint,
          method: "GET",
          token: await getAccessTokenSilently(),
        });

        setFileConfig(response);
        setOriginalFileConfig(response);
        setIsLoading(false);
        setErrorMsg("");
        return; // Success, exit the function
      } catch (error) {
        lastError = error;
        attempts++;

        if (attempts < MAX_RETRIES) {
          onNotification(`Attempt ${attempts} failed. Retrying...`, "warning");
          await new Promise((resolve) => setTimeout(resolve, 1000 * attempts)); // Exponential backoff
        }
      }
    }

    // If we've reached this point, all retries have failed
    console.error(lastError);
    const errorMessage = getErrorMessage(lastError, fields);
    setErrorMsg(errorMessage);
    onNotification(
      `Failed to fetch file configuration after ${MAX_RETRIES} attempts. Please try again later.`,
      "error"
    );
    setIsLoading(false);
  }, [currentStateData?.environment?.environmentId, getAccessTokenSilently, onNotification, fields]);

  useEffect(() => {
    if (currentStateData?.environment?.environmentId) {
      fetchFileConfig();
    }
  }, [currentStateData?.environment?.environmentId]);

  const handleDataChange = (newData) => {
    setFileConfig(newData);
  };

  const handleSave = async () => {
    try {
      validateData(fields, fileConfig);

      const endpoint = `v1/environment/${currentStateData?.environment?.environmentId}/file`;
      const response = await fetchDirectly({
        fullUrl: endpoint,
        method: "PUT",
        body: fileConfig,
        token: await getAccessTokenSilently(),
      });

      if (response && Object.keys(response).length > 0) {
        setFileConfig(response);
        setOriginalFileConfig(response);
      } else {
        // If no response or empty response is returned, fetch the updated config
        await fetchFileConfig();
      }
      setErrorMsg("");
      onNotification("File configuration saved successfully", "success");
    } catch (error) {
      console.error(error);
      const errorMessage = getErrorMessage(error, fields);
      setErrorMsg(errorMessage);
      onNotification(errorMessage, "error");
    }
  };

  const handleCancel = () => {
    setFileConfig(originalFileConfig);
    setErrorMsg("");
    onNotification("Changes discarded", "info");
  };

  const hasChanges = JSON.stringify(fileConfig) !== JSON.stringify(originalFileConfig);

  return (
    <FormComponent
      title={title}
      fields={fields}
      data={fileConfig}
      onDataChange={handleDataChange}
      isLoading={isLoading}
      onSave={handleSave}
      onCancel={handleCancel}
      errorMsg={errorMsg}
      hasChanges={hasChanges}
    />
  );
};

export default FileConfig;
