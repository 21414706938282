import React from "react";

const CustomInput = ({ label, name, value, onChange, placeholder, required, disabled, type = "text" }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-zinc-300 mb-1">{label}</label>
    <input
      type={type}
      name={name}
      value={value || ""}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      className="w-full text-white border border-zinc-600 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-violet-500 placeholder-zinc-400 bg-opacity-20 bg-zinc-700 backdrop-filter backdrop-blur-sm"
    />
  </div>
);

export default CustomInput;
