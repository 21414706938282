import React, { useState, useEffect } from "react";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import CustomDateTimeInput from "./CustomDateTimeInput";
import CustomBooleanInput from "./CustomBooleanInput";
import SkeletonLoader from "./SkeletonLoader";
import dayjs from "dayjs";

const FormComponent = ({ title, fields, data, onDataChange, isLoading, onSave, onCancel, errorMsg, hasChanges }) => {
  const [isTableView, setIsTableView] = useState(window.innerWidth >= 800);
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    const handleResize = () => setIsTableView(window.innerWidth >= 800);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => setShowSkeleton(false), 50);
      return () => clearTimeout(timer);
    } else {
      setShowSkeleton(true);
    }
  }, [isLoading]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue =
      type === "checkbox" ? checked : type === "datetime" ? (value ? value : null) : value?.trim() || null;
    onDataChange({ ...data, [name]: newValue });
  };

  const renderField = (field) => {
    const commonProps = {
      key: field.field,
      id: field.field,
      name: field.field,
      value: data[field.field],
      onChange: handleChange,
      required: field.required,
      className: field.type !== "boolean" ? "w-full bg-gray-700 text-white border-gray-600 rounded-md" : "",
    };

    switch (field.type) {
      case "select":
        return <CustomSelect {...commonProps} options={field.options} />;
      case "datetime":
        return (
          <CustomDateTimeInput
            {...commonProps}
            value={data[field.field] ? dayjs(data[field.field]).format("YYYY-MM-DDTHH:mm") : ""}
          />
        );
      case "boolean":
        return <CustomBooleanInput {...commonProps} label={field.headerName} />;
      default:
        return <CustomInput {...commonProps} placeholder={`Enter ${field.headerName}`} />;
    }
  };

  return (
    <div className={`${isTableView ? "min-w-[300px]" : ""} w-full max-w-2xl`}>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">{title}</h2>
        {hasChanges && (
          <div className="space-x-2 transition-opacity duration-300 ease-in-out text-xs">
            <button className="bg-zinc-500 text-white px-4 py-2 rounded-md hover:bg-zinc-600" onClick={onCancel}>
              Cancel
            </button>
            <button className="bg-violet-500 text-white px-4 py-2 rounded-md hover:bg-violet-600" onClick={onSave}>
              Save Changes
            </button>
          </div>
        )}
      </div>

      {errorMsg && <div className="bg-red-500 text-white p-3 rounded-md mb-4">{errorMsg}</div>}

      <div className="text-sm relative">
        <div
          className={`absolute inset-0 z-10 transition-opacity duration-100 ${
            showSkeleton ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          {fields.map((field) => (
            <SkeletonLoader
              key={field.field}
              type={field.type === "select" ? "select" : "input"}
              className="opacity-50 mb-4"
            />
          ))}
        </div>
        <div className={`transition-opacity duration-100 ${showSkeleton ? "opacity-0" : "opacity-100"}`}>
          {fields.map((field) => (
            <div key={field.field} className={`${isTableView ? "flex items-center" : ""}`}>
              <label
                htmlFor={field.field}
                className={`${
                  isTableView ? "w-40 min-w-[160px] pr-4" : "block mb-1"
                } text-sm font-medium text-gray-400`}
              >
                {field.type !== "boolean" ? field.headerName : ""}
              </label>
              <div className="flex-1">{renderField(field)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FormComponent;
