import React, { useState, useEffect, useContext, useCallback } from "react";
import { useAuth } from "contexts/AuthContext";
import AccountContext from "contexts/AccountContext";
import { fetchDirectly } from "common/apiUtils";
import FormComponent from "../FormComponent";
import { validateData, getErrorMessage } from "../../../environment/validation";

const VivoxConfig = ({ onNotification }) => {
  const { getAccessTokenSilently } = useAuth();
  const { currentStateData } = useContext(AccountContext);

  const [vivoxConfig, setVivoxConfig] = useState({});
  const [originalVivoxConfig, setOriginalVivoxConfig] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const title = "Vivox Settings";
  const fields = [
    { headerName: "Enabled", field: "enabled", type: "boolean", required: false },
    { headerName: "Server", field: "server", type: "string", required: true },
    { headerName: "Key Issuer", field: "key_issuer", type: "string", required: true },
    { headerName: "Key Lifetime", field: "key_lifetime", type: "number", required: true },
    {
      headerName: "Signing Algorithm",
      field: "signature_algorithm",
      type: "select",
      required: true,
      options: [{ id: "HS256", title: "HS256" }],
    },
    { headerName: "Signing Key", field: "signing_key", type: "password", required: true },
  ];

  const MAX_RETRIES = 3;

  const fetchVivoxConfig = useCallback(async () => {
    let attempts = 0;
    let lastError;

    while (attempts < MAX_RETRIES) {
      try {
        const endpoint = `v1/environment/${currentStateData?.environment?.environmentId}/vivox`;
        const response = await fetchDirectly({
          fullUrl: endpoint,
          method: "GET",
          token: await getAccessTokenSilently(),
        });
        setVivoxConfig(response);
        setOriginalVivoxConfig(response);
        setIsLoading(false);
        setErrorMsg("");
        return; // Success, exit the function
      } catch (error) {
        lastError = error;
        attempts++;

        if (attempts < MAX_RETRIES) {
          onNotification(`Attempt ${attempts} failed. Retrying...`, "warning");
          await new Promise((resolve) => setTimeout(resolve, 1000 * attempts)); // Exponential backoff
        }
      }
    }

    // If we've reached this point, all retries have failed
    console.error(lastError);
    const errorMessage = getErrorMessage(lastError, fields);
    setErrorMsg(errorMessage);
    onNotification(
      `Failed to fetch Vivox configuration after ${MAX_RETRIES} attempts. Please try again later.`,
      "error"
    );
    setIsLoading(false);
  }, [currentStateData?.environment?.environmentId, getAccessTokenSilently, onNotification, fields]);

  useEffect(() => {
    if (currentStateData?.environment?.environmentId) {
      fetchVivoxConfig();
    }
  }, [currentStateData?.environment?.environmentId]);

  const handleDataChange = (newData) => {
    setVivoxConfig(newData);
  };

  const handleSave = async () => {
    try {
      validateData(fields, vivoxConfig);

      const endpoint = `v1/environment/${currentStateData?.environment?.environmentId}/vivox`;
      const response = await fetchDirectly({
        fullUrl: endpoint,
        method: "PUT",
        body: vivoxConfig,
        token: await getAccessTokenSilently(),
      });
      if (response && Object.keys(response).length > 0) {
        setVivoxConfig(response);
        setOriginalVivoxConfig(response);
      } else {
        // If no response or empty response is returned, fetch the updated config
        await fetchVivoxConfig();
      }
      setErrorMsg("");
      onNotification("Vivox configuration saved successfully", "success");
    } catch (error) {
      console.error(error);
      const errorMessage = getErrorMessage(error, fields);
      setErrorMsg(errorMessage);
      onNotification(errorMessage, "error");
    }
  };

  const handleCancel = () => {
    setVivoxConfig(originalVivoxConfig);
    setErrorMsg("");
    onNotification("Changes discarded", "info");
  };

  const hasChanges = JSON.stringify(vivoxConfig) !== JSON.stringify(originalVivoxConfig);

  return (
    <FormComponent
      title={title}
      fields={fields}
      data={vivoxConfig}
      onDataChange={handleDataChange}
      isLoading={isLoading}
      onSave={handleSave}
      onCancel={handleCancel}
      errorMsg={errorMsg}
      hasChanges={hasChanges}
    />
  );
};

export default VivoxConfig;
